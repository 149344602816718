import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import './AvaConfig.css';
const Decimal = require('decimal.js');
//TODO, make input boxes be side by side 
function AvaConfig({ runAnalysis, isLoading }) {
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [radius, setRadius] = useState('');
    const [resolution, setResolution] = useState('');
    const [minHeight, setMinHeight] = useState('');
    const [maxHeight, setMaxHeight] = useState('');
    const [validInputs, setValidInputs] = useState('Please enter your configurations');


    const handleInputchange = (event,setValue,re,minVal,maxValue) => {
        const value = event.target.value;

        if (value === '' || (re.test(value) && parseFloat(value) >= minVal && parseFloat(value) <= maxValue)) {
            setValue(value);
        }
    };

    useEffect(() => {
        if (longitude === '' || latitude === '' || radius === '' || resolution === '' || minHeight === '' || maxHeight === '') {
            setValidInputs("Please enter your configurations")
        }
        else if (parseFloat(Decimal(radius).mod(Decimal(resolution)).toString()) !==0) {
            setValidInputs("Radius must be divisible by resolution");
        } 
        else if (radius/resolution > 20) {
            setValidInputs("Radius must be less than 20 times the resolution");
        }
        else if (parseFloat(maxHeight) < parseFloat(minHeight)) {
            setValidInputs("Max. height must be less than min.");
        }
        else {
            setValidInputs("Run Viewshed Analysis");
        }

  }, [longitude, latitude, radius, resolution, minHeight, maxHeight]);

    return(
        <div className = "avaConfig">
            <div className = "inputGroup">
                <div className='inputDescription'>
                    Observer Location
                </div>
                <div className ="inputInstructions">
                    Enter a longitude from -180 to 180 degrees and a latitude from -90 to 90 degrees
                </div>
                <Form.Group className="input">
                    <Form.Control
                    className='inputBox'
                    type="number"
                    steo="0.1"
                    value={longitude}
                    onChange={(event) => handleInputchange(event,setLongitude,/^-?\d*\.?\d+$/,-180,180)}
                    placeholder="Longitude"
                    />
                    <Form.Control
                    className='inputBox'
                    type="number"
                    steo="0.1"
                    value={latitude}
                    onChange={(event) => handleInputchange(event,setLatitude,/^-?\d*\.?\d+$/,-90,90)}
                    placeholder="Latitude"
                    />
                </Form.Group>
            </div>
            <div className = "inputGroup">
                <div className='inputDescription'>
                    Height Range
                </div>
                <div className ="inputInstructions">
                    Enter a minimum and maximum observer height to calculate from in meters
                </div>
                <Form.Group className='input'>
                    <Form.Control
                    className='inputBox'
                    type="number"
                    value={minHeight}
                    onChange={(event) => handleInputchange(event,setMinHeight,/^\d+$/,0,10)}
                    placeholder="Min. Height"
                    />
                    <Form.Control
                    className='inputBox'
                    type="number"
                    value={maxHeight}
                    onChange={(event) => handleInputchange(event,setMaxHeight,/^\d+$/,minHeight,10)}
                    placeholder="Max. Height"
                    />
                </Form.Group>
            </div>
            <div className = "inputGroup">
                <div className='inputDescription'>
                    Viewshed Specifications
                </div>
                <div className ="inputInstructions">
                Enter a radius from 1 to 10 kilometers and a resolution from .1 to 1 kilometers
                </div>
                <Form.Group className="input">
                    <Form.Control
                    className='inputBox'
                    type="number"
                    value={radius}
                    onChange={(event) => handleInputchange(event,setRadius,/^\d+$/,1,10)}
                    placeholder="Radius"
                    />
                    <Form.Control
                    className='inputBox'
                    type="number"
                    steo="0.001"
                    value={resolution}
                    onChange={(event) => handleInputchange(event,setResolution,/^\d*\.?\d*$/,.1,1)}
                    placeholder="Resolution"
                    />
                </Form.Group>
            </div>
            <div className='inputGroup'>
                <div className = 'runButton'> 
                    <Button variant="success" 
                        className="runButton" 
                        disabled={validInputs === "Run Viewshed Analysis" ? false : true}
                        onClick={() => runAnalysis("?longitude="+longitude+"&latitude="+latitude+"&radius="+radius+"&resolution="+resolution+"&minimum_height="+minHeight+"&maximum_height="+maxHeight)}>
                            <div>
                            {isLoading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }} 
                                />
                               }
                                </div>
                        {isLoading ? "Loading": validInputs}
                    </Button>
               </div>
            </div>
         </div>
           
    )
  }
  
  export default AvaConfig;


  