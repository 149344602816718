import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import "./Analytics.css";


import { LineChart, Line, CartesianGrid, XAxis, YAxis, Label, Legend } from 'recharts';

import 'bootstrap/dist/css/bootstrap.min.css';


function Analytics({ analyticsInfo }){
const [currentGraph, setCurrentGraph] = useState(["Viewable Area", "viewable_area"] )

  function handleGraphChange(newGraph) { 
    setCurrentGraph(newGraph)
  }

  function GraphButton({ newGraph, buttonText }) {
    const disabled = (currentGraph[1] !== newGraph[1] && analyticsInfo.length >  1)
    return (
      <Button 
          style={{fontWeight: "bold"}}
            variant={disabled ? "danger" : "success"}
            onClick={() => handleGraphChange(newGraph)}
            disabled={disabled ? false: true}>
              {buttonText}
        </Button>
    );
  }



  return (
    <div className="analyticsContainer">
      <div className="chartContainer">
        {analyticsInfo !== null && analyticsInfo.length > 1 ? 
          <LineChart width={825} height={300} data={analyticsInfo}>
            <Line type="monotone" name={currentGraph[0]}
            dataKey={currentGraph[1]}
            stroke="#ffffff"
            strokeWidth={3} />
            <CartesianGrid stroke="#ffffff" />
            <XAxis dataKey="height" stroke="#ffffff" style={{margin:10}}>
              <Label value="Height (m)" dy={5} position="insideBottom" 
                  style={{ fontWeight: 'bold', fill: '#ffffff', fontSize: '16px'}}/>
            </XAxis> 
            <YAxis stroke="#ffffff"> 
            <Label value={currentGraph[0]} offset={0} position="insideLeft" angle = "-90" dx={10}
                  style={{ fontWeight: 'bold', fill: '#ffffff', fontSize: '16px', textAnchor: 'middle' }}/>
            </YAxis>
         </LineChart>
         :<div className="noGraphText">Please Enter a Height Range Greater than 1 to View a Graph
      </div>}
    </div>

      
      <div className= "tableContainer">
        <Table className="table table-striped"
        variant="success"
        size="sm"
        bordered="true"
        >
        <thead className="text-center">
          <tr>
            <th className=".noGraphText">Height (meters)</th>
            <th>
              <GraphButton 
              newGraph={["Viewable Area","viewable_area"]} 
              buttonText="Viewable Area (km²)"/>
              </th>
            <th>
              <GraphButton 
                newGraph={["% Possible Visibility","prct_of_visibility"]} 
                buttonText="% Possible Visibility"/>
            </th>
            <th>
              <GraphButton 
                newGraph={["# of Views","num_of_views"]} 
                buttonText="# of Views"/>
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
        {analyticsInfo.map((heightAnalysis) => (
            <tr>
              <td>{heightAnalysis.height}</td>
              <td>{heightAnalysis.viewable_area}</td>
              <td>{heightAnalysis.prct_of_visibility}</td>
              <td>{heightAnalysis.num_of_views}</td>
              </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </div>
        
       );
};

export default Analytics;
