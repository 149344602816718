import React, { useState} from 'react';
import './Ava.css'; 
import AvaConfig from './AvaConfig'
import Analysis from './Analysis/Analysis';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";



function Ava() {
    const [avaResult, setAvaResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    function runAnalysis(configUrl) {
        setIsLoading(true)
        axios
        .get("https://automated-viewshed-analysis.uc.r.appspot.com/ava/" + configUrl)
        .then(response => {
            setAvaResult(response.data);
            setIsLoading(false);
        })
        .catch(error => console.log(error)) 
    }

    return(
        <div className = "ava">
            <AvaConfig 
            runAnalysis={runAnalysis}
            isLoading={isLoading}
            />
            <Analysis
            avaResult={avaResult}
            isLoading={isLoading}/>
            
       </div>
    )
  }
  
  export default Ava;

//   http://127.0.0.1:8000

// "http://automated-viewshed-analysis.uc.r.appspot.com/ava/" 