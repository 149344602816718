import React from 'react';
import './App.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Ava from './components/Ava';

function App() {
  return(
      <div className="app">
        <Ava />
        <div className='disclosure'>
          NOTICE: This is a demo site. The data used in this site is not real and is not intended to be used for any purpose other than demonstration.
        </div>
     </div>
  )
}

export default App;