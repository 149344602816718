import React, { useState, useEffect } from "react";
import {GoogleMap, useJsApiLoader, Polygon, Marker } from '@react-google-maps/api';
import "./AvaMap.css";


function AvaMap({ squares, observerDetails, mapKeyData, isLoading }) {
    const [polygons, setPolygons] = useState([])

    const clearMap = () => {
        setPolygons([]);
      };

  
    const containerStyle = {
      width: '400px%',
      height: '550px',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    };
    
    const center = {
      lat: 40.7608,
      lng: -102.8910
    };
  
  
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })
  
    const [map, setMap] = React.useState(null)
  
    // const onLoad = React.useCallback(function callback(map) {
    //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //   const bounds = new window.google.maps.LatLngBounds(center);
    //   map.fitBounds(bounds);
  
  
  
    //   setMap(map)
    // }, [])
  
  
    const onLoad = React.useCallback(function callback(mapInstance) {
      setMap(mapInstance);
    }, []);
  
    const onUnmount = useEffect(() => {
      setMap(null)
    }, [observerDetails]);

    useEffect(() => {
      if(squares!== null){
        const newPolygons = squares
        setPolygons(newPolygons)
      }
      setMap(null)
  
    }, [observerDetails, squares]);

    function keySquare(color, text) {
      if (text === "Invisible") {
        color = "rgba(" + color + ", 0, 0, .75)"
      }
      else {
        color = "rgba(0, " + color + ", 0, .75)"
      }
      return (
        <div>
          {text}
          <div style={{width: '40px',
                      height: '40px',
                      background: color}}>
          </div>
        </div>
      )
    }
  
  
    return isLoaded ? (
        <>
      <GoogleMap
      mapContainerStyle={containerStyle}
      center={observerDetails ? {
        lat: observerDetails.latitude,
        lng: observerDetails.longitude
      }
      : center}
      zoom={observerDetails ? 15: 4}
      streetViewControl={false}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options = {{
        streetViewControl: false,
        mapTypeId: 'satellite'
      }}
    >

       {!isLoading && squares !== null ?
          <div > 
              {polygons.map((polygon, index) => (
          <Polygon
            key={index}
          paths={polygon.square_coords}
          options={{
              strokeOpacity: 0,
              strokeWeight: 0,
              fillColor: `rgb(${polygon.color[0]}, ${polygon.color[1]}, ${0})`,
              fillOpacity: 0.5,
              visible: true, }}
          />
        ))}
          <Marker
            position={{
              lat: observerDetails.latitude,
              lng: observerDetails.longitude
            }}>
          </Marker>
          </div>
          : <></>
          }
          {!isLoading && mapKeyData !== null ? 
          <div className="mapKey-container">
            <div className="mapKey-title">
              Visibility Key
            </div>
            <div className="mapKey">
              {mapKeyData.map((data) => 
              keySquare(data.color, data.text)
                )}
           </div>

          </div>: 
      <></>}
      </GoogleMap>
      </>    
    ) : <></>
  }
  export default AvaMap;