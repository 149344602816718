import React, { useState, useEffect } from "react";
import "./Analysis.css";
import AvaMap from "./AvaMap"
import Analytics from "./Analytics";
import Button from 'react-bootstrap/Button';

function Analysis({ avaResult, isLoading }){

  const [granMode, setGranMode] = useState(true)
  const [avaMap,  setAvaMap] = useState(null)

  useEffect(() => {
    setAvaMap(null)
    setAvaMap(<AvaMap
      squares={avaResult ? avaResult.squares: null}
      observerDetails={avaResult ? avaResult.observer_details: null}
      mapKeyData={avaResult ? avaResult.map_key_data: null}/>)

    }, [avaResult]);

  const handleInputchange = () => { 
    setGranMode(!granMode)
  };
  
  return (
    <div className="analysisContainer">
      <div className="analysisOptions">
      <Button 
        variant="success" 
        className="optionButton"
        onClick={handleInputchange}
        disabled={!granMode && avaResult !== null ? false : true} >
          Map Highlight
      </Button>
      <Button 
        variant="success" 
        className="optionButton"
        onClick={handleInputchange}
        disabled={granMode && avaResult !== null  ? false : true} >
          Graphs and Analytics
        </Button>

      </div>
      {granMode ? (avaMap): 
        <>
          <Analytics
            analyticsInfo={avaResult ? avaResult.height_analysis: null}>
          </Analytics>
    
         </>}
    </div>
  );
};

export default Analysis;

      
 